import qs from 'qs'
import React from 'react'
import Base from 'components/Base'

//function shouldUpdateScroll({ routerProps }) {
//  const { hash } = routerProps.location

//  if (!hash) {
//    return true
//  }

//  const element = document.querySelector(hash)

//  if (!element) {
//    return true
//  }

//  return false
//}

function wrapRootElement({ element }: { element: React.ReactNode }) {
  return <Base>{element}</Base>
}

const PATHS_WITH_FAKED_UTMS = [
  {
    path: '/humana2023',
    utmCampaign: 'hum-enrollment-1-duo',
    utmMedium: 'mail',
    utmSource: 'partner-hum',
  },
  {
    path: '/optumaco',
    utmCampaign: 'optm_enrollment_1_a',
    utmMedium: 'mail',
    utmSource: 'partner-optm',
  },
  {
    path: '/optumaco1',
    utmCampaign: 'optm-partnerled-enrollment1',
    utmMedium: 'sms',
    utmSource: 'partner-optm',
  },
  {
    path: '/optumaco2',
    utmCampaign: 'optm-partnerled-enrollment2',
    utmMedium: 'sms',
    utmSource: 'partner-optm',
  },
  {
    path: '/optumaco3',
    utmCampaign: 'optm-partnerled-enrollment3',
    utmMedium: 'sms',
    utmSource: 'partner-optm',
  },
  {
    path: '/optumstart',
    utmCampaign: 'optm-direct-mail',
    utmMedium: 'mailer',
    utmSource: 'partner-optm',
  },
  {
    path: '/optum2023',
    utmCampaign: 'optm_enrollment_1_b',
    utmMedium: 'mail',
    utmSource: 'partner-optm',
  },
  {
    path: '/optum2024',
    utmCampaign: 'optm_enrollment_1_c',
    utmMedium: 'mail',
    utmSource: 'partner-optm',
  },
]

function onRouteUpdate({ location }: { location: Location }) {
  PATHS_WITH_FAKED_UTMS.map((item) => {
    if (!location.pathname.includes(item.path)) return

    const queryParams = {
      ...qs.parse(location.search),
      ...{
        utm_campaign: item.utmCampaign,
        utm_medium: item.utmMedium,
        utm_source: item.utmSource,
      },
    }

    window.history.replaceState({}, '', `${location.pathname}?${qs.stringify(queryParams)}`)
  })
}

export {
  onRouteUpdate,
  //shouldUpdateScroll,
  wrapRootElement,
}
