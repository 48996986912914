// logrocket doesn't respect `data-private={false}`
export const INPUT_PHI_PROPS = {
  'data-private': true,
}

export function getUserType(email?: string) {
  if (!email) return 'external'

  if (
    email.includes('agebold.com') ||
    email.includes('mailinator.com') ||
    !email.includes('xelpmoc.in')
  ) {
    return 'internal'
  }

  return 'external'
}
