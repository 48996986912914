/* eslint-disable no-console */
import _ from 'lodash'
import * as environment from 'libs/environment'
import constants from 'styles/constants.module.scss'

export function toConsole(
  value: { data?: {} | string; message: string } | string,
  color = constants.CYAN
) {
  if (environment.isProduction) return

  const colorParameter = `color: ${color}`

  if (_.isObject(value)) {
    const { data, message } = value

    if (_.isObject(data)) {
      const dataFiltered = _.pickBy(data)

      if (_.isEmpty(dataFiltered)) return

      console.groupCollapsed(`%c${message.toUpperCase()}`, colorParameter)
      console.table(dataFiltered)
      console.log(JSON.stringify(dataFiltered, null, 2))
      console.groupEnd()
    } else {
      console.log(`%c${message.toUpperCase()} - ${data}`, colorParameter)
    }
  } else {
    console.log(`%c${value}`, colorParameter)
  }
}
