import * as api from 'libs/api'
import * as subscriptions from 'libs/subscriptions'

export async function addDefaultCardForUser(stripeCardToken: string) {
  try {
    return await api.post('user/cards', { stripeCardToken })
  } catch (error) {
    return { error }
  }
}

export async function getInvoicesForUser() {
  try {
    return await api.get('invoices')
  } catch (error) {
    return { error }
  }
}

export async function getPaymentMethodForUser() {
  try {
    return await api.get('paymentMethod')
  } catch (error) {
    return { error }
  }
}

export async function getPlans() {
  try {
    const result = await api.get('plans')

    let annualPlan
    let monthlyPlan
    let biAnnualPlan
    let freePlan

    result.data.plans.forEach((plan) => {
      if (subscriptions.isFreePlan(plan)) {
        freePlan = plan
      }
      if (subscriptions.isMonthlyPlan(plan)) {
        monthlyPlan = plan
      }
      if (subscriptions.isBiAnnualPlan(plan)) {
        biAnnualPlan = plan
      }
      if (subscriptions.isAnnualPlan(plan)) {
        annualPlan = plan
      }
    })

    result.data.plans = [freePlan, annualPlan, monthlyPlan, biAnnualPlan]

    return result
  } catch (error) {
    return { error }
  }
}

export async function getSubscriptionForUser() {
  try {
    return await api.get('getSubscriptionForUser')
  } catch (error) {
    return { error }
  }
}

export async function subscribeUserToPlan(data: unknown) {
  try {
    return await api.post('subscribeUser', data)
  } catch (error) {
    return { error }
  }
}

export async function updateSubscription(subscriptionId: string, data: unknown) {
  try {
    return await api.post(`subscription/${subscriptionId}`, data)
  } catch (error) {
    return { error }
  }
}
