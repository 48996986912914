export default {
  ACCESS_TOKEN_KEY: 'accessToken',

  ANNOUNCEMENT_DISMISSED: 'dismissed',

  DASHBOARD_CHALLENGE_KEY: 'dashboard-challenge',
  DASHBOARD_CHALLENGE_DISMISSED: 'dismissed',

  EMAIL_KEY: 'email',

  ENTERPRISE_OCTOPUS_ASSESSMENTS_3_MONTHS_MODAL_KEY: 'enterprise-oschner-assessments-3-months',
  ENTERPRISE_OCTOPUS_ASSESSMENTS_6_MONTHS_MODAL_KEY: 'enterprise-oschner-assessments-6-months',
  ENTERPRISE_OCTOPUS_ASSESSMENTS_MODAL_DISMISSED: 'dismissed',

  ENTERPRISE_OCTOPUS_REDIRECT_URL: 'enterprise-ochsner-redirect-url',

  ENTERPRISE_EXTERNAL_ID_KEY: 'enterprise-external-id',

  ENTERPRISE_WELCOME_MODAL: 'enterprise-welcome-modal',

  FAVORITES_ANNOUNCEMENT_KEY: 'favorites-announcement',

  FIRST_LESSON_REMINDER_KEY: 'first-lesson-reminder',

  FROM_APP: 'from-app',

  HOME_KEY: 'landing',
  HOME_ARTHRITIS: 'arthritis',
  HOME_BALANCED: 'balanced',
  HOME_SPECIAL: 'special',
  HOME_ORCA: 'orca',
  HOME_REEF: 'reef',

  MASS_ADVANTAGE: 'mass-advantage',

  MODAL_DISMISSED: 'dismissed',
  MODAL_SHOWN: 'shown',

  PHONE_NUMBER_MODAL_KEY: 'phone-number',

  MOBILE_APP_DOWNLOAD_MODAL_KEY: 'mobile-app-download',

  PROMO_CODE_KEY: 'promo-code',

  PROMO_CODE_COUPON_KEY: 'PROMO_CODE_COUPON',

  REFERRAL_MODAL_POST_ONBOARDING_10_DAYS_DISMISSED: 'dismissed',
  REFERRAL_SOURCE_KEY: 'referralSource',
  REFERRAL_SOURCE_PAID: 'paid',

  REFRESH_TOKEN_KEY: 'refreshToken',

  SKILL_BUILDING_KEY: 'skill-building',

  UID_KEY: 'uid',

  VISITOR_ID_KEY: 'visitor-id',
}
