import { differenceInYears, parse } from 'date-fns'
import _ from 'lodash'
import * as Yup from 'yup'

// https://github.com/jquense/yup#stringemailmessage-string--function-schema
Yup.addMethod(Yup.string, 'email', function validateEmail(message) {
  return this.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
    message,
    name: 'email',
    excludeEmptyString: true,
  })
})

export const PASSWORDS_MATCH_MESSAGE = 'Passwords must match'
export const REQUIRED_MESSAGE = 'Required'

const lengthMinMessage = (length: number) => `Must be ${length} or more characters`
const lengthMaxMessage = (length: number) => `Must be ${length} or less characters`

const AGE_MAX = 110
const AGE_MIN = 20
export const AGE = Yup.number()
  .min(AGE_MIN, `Must be at least ${AGE_MIN}`)
  .max(AGE_MAX, `Must be at most ${AGE_MAX}`)
  .required(REQUIRED_MESSAGE)

export const CHECKBOX = Yup.bool().oneOf([true], REQUIRED_MESSAGE)
export const CHECKBOXES = Yup.array().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE)

export const DATE_OF_BIRTH = Yup.string()
  .test('date-of-birth', `Must be between ${AGE_MIN} and ${AGE_MAX} years old`, (value) => {
    if (!value) return false
    const years = differenceInYears(new Date(), parse(value, 'MM/dd/yyyy', new Date()))
    return years >= AGE_MIN && years <= AGE_MAX
  })
  .required(REQUIRED_MESSAGE)
export const DATE_OF_BIRTH_OPTIONAL = Yup.string()
  .test('date-of-birth', `Must be between ${AGE_MIN} and ${AGE_MAX} years old`, (value) => {
    if (!value) return true
    const years = differenceInYears(new Date(), parse(value, 'MM/dd/yyyy', new Date()))
    return years >= AGE_MIN && years <= AGE_MAX
  })
  .notRequired()

export const EMAIL = Yup.string().email('Invalid email').required(REQUIRED_MESSAGE)

const MEMBER_ID_MAX_LENGTH = 25
//const NON_DIGIT_REGEX = /\D/
export const MEMBER_ID = Yup.string()
  .max(MEMBER_ID_MAX_LENGTH, lengthMaxMessage(MEMBER_ID_MAX_LENGTH))
  //.test('contains-non-digit', NUMBER_INVALID_MESSAGE, (value) => !NON_DIGIT_REGEX.test(value))
  .required(REQUIRED_MESSAGE)
export const MEMBER_ID_OPTIONAL = Yup.string()
  .max(MEMBER_ID_MAX_LENGTH, lengthMaxMessage(MEMBER_ID_MAX_LENGTH))
  .notRequired()

const NAME_INVALID_MESSAGE = 'Please enter a name with no numbers or symbols'
const NAME_REGEX = /^[^\d!#$%&()*+<>@^]+$/
export const NAME = Yup.string()
  .matches(NAME_REGEX, NAME_INVALID_MESSAGE)
  .required(REQUIRED_MESSAGE)

const NUMBER_NEGATIVE_INVALID_MESSAGE = 'Please enter a non-negative number'
const NUMBER_OUT_OF_RANGE_MESSAGE = 'Please enter a number less than 100'
export const NUMBER = Yup.number()
  .positive(NUMBER_NEGATIVE_INVALID_MESSAGE)
  .test('out-of-range', NUMBER_OUT_OF_RANGE_MESSAGE, (value) => value <= 100)
export const NUMBER_REQUIRED = Yup.number()
  .positive(NUMBER_NEGATIVE_INVALID_MESSAGE)
  .test('out-of-range', NUMBER_OUT_OF_RANGE_MESSAGE, (value) => value <= 100)
  .required(REQUIRED_MESSAGE)
export const NUMBER_POSITIVE_REQUIRED = Yup.number()
  .positive(NUMBER_NEGATIVE_INVALID_MESSAGE)
  .required(REQUIRED_MESSAGE)

export const PASSWORD_LENGTH_MIN = 6
export const PASSWORD = Yup.string()
  .min(PASSWORD_LENGTH_MIN, lengthMinMessage(PASSWORD_LENGTH_MIN))
  .required(REQUIRED_MESSAGE)

const PHONE_NUMBER_INVALID_MESSAGE = 'Invalid phone number'
const PHONE_NUMBER_REGEX = /^(1-)?\d{3}-\d{3}-\d{4}$/
export const PHONE_NUMBER = Yup.string()
  .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_INVALID_MESSAGE)
  .required(REQUIRED_MESSAGE)
export const PHONE_NUMBER_OPTIONAL = Yup.string()
  .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_INVALID_MESSAGE)
  .notRequired()

const POSTAL_CODE_INVALID_MESSAGE = 'Invalid ZIP code'
const POSTAL_CODE_LENGTH_MIN = 5
export const POSTAL_CODE_LENGTH_MAX = 10 // XXXXX-XXXX
export const POSTAL_CODE = Yup.string()
  .matches(/^(\d{5}(-\d{4})?)$/, POSTAL_CODE_INVALID_MESSAGE)
  .test('postal-code', POSTAL_CODE_INVALID_MESSAGE, (value) => {
    if (!value) return true
    return value.length === POSTAL_CODE_LENGTH_MIN || value.length === POSTAL_CODE_LENGTH_MAX
  })
  .required(REQUIRED_MESSAGE)
export const POSTAL_CODE_OPTIONAL = Yup.string()
  .matches(/^(\d{5}(-\d{4})?)$/, POSTAL_CODE_INVALID_MESSAGE)
  .test('postal-code', POSTAL_CODE_INVALID_MESSAGE, (value) => {
    if (!value) return true
    return value.length === POSTAL_CODE_LENGTH_MIN || value.length === POSTAL_CODE_LENGTH_MAX
  })
  .notRequired()

const REEF_ORCA_EXTERNAL_ID_LENGTH = 10
export const REEF_ORCA_EXTERNAL_ID = Yup.string()
  .length(REEF_ORCA_EXTERNAL_ID_LENGTH, `Must be ${REEF_ORCA_EXTERNAL_ID_LENGTH} characters`)
  .matches(/^[abs]\d{9}$/i, 'Invalid code')
  .required(REQUIRED_MESSAGE)
export const REEF_ORCA_EXTERNAL_ID_OPTIONAL = Yup.string()
  .length(REEF_ORCA_EXTERNAL_ID_LENGTH, `Must be ${REEF_ORCA_EXTERNAL_ID_LENGTH} characters`)
  .matches(/^[abs]\d{9}$/i, 'Invalid code')
  .notRequired()

export const REMINDERS_DAYS = Yup.array()
  .of(Yup.string())
  .test('commitment-days', REQUIRED_MESSAGE, (value) => {
    if (!value || _.isEmpty(value) || (_.size(value) === 1 && value[0] === 'None')) return false
    return true
  })
  .required(REQUIRED_MESSAGE)

export const REQUIRED = Yup.string().required(REQUIRED_MESSAGE)
export const REQUIRED_ARRAY = Yup.array().of(Yup.string()).required(REQUIRED_MESSAGE)
