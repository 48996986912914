export const INTEGRATION = {
  // https://developers.facebook.com/docs/facebook-pixel/reference
  facebook: {
    // events: {
    //   LEAD: 'Lead',
    //   PURCHASE: 'Purchase',
    //   SIGN_UP: 'CompleteRegistration', //this isn't accurate anymore ...
    // },
    parameters: {
      //CONTENT_NAME: 'content_name',
      CONTENT_TYPE: 'content_type',
      CURRENCY: 'currency',
      ITEM_IDS: 'content_ids',
      VALUE: 'value',
    },
  },
}

// pages
export const HOME_PAGE = 'Home'
export const HOME_SPECIAL = 'Special'
export const AUTHED_HOME_PAGE = 'Authenticated Home'
export const PROFILE_PAGE = 'Profile page'
export const CLASSES_PAGE = 'Classes page'
export const EXPLORE_PAGE = 'Explore page'
export const RSVP_PAGE = 'RSVP page'
export const REFERRALS_PAGE = 'Referrals'
export const BALANCE_INTENSIVE = 'New Year balance intensive'
export const ARTHRITIS = 'Arthritis'
export const SETTINGS_PAGE = 'Settings'
export const ONBOARDING_INTRO = 'Onboarding intro page'
export const ONBOARDING_QUESTIONS_PAGE = 'Onboarding questions'
export const ONBOARDING_PROFILE_PAGE = 'Onboarding profile'
export const ONBOARDING_PAYMENT_PAGE = 'Onboarding payment'
export const SETTINGS_CHANGE_SUBSCRIPTION_PAGE = 'Settings change subscription page'
export const SETTINGS_CHANGE_SUBSCRIPTION_SUCCESS_PAGE = 'Settings change subscription success page'

export const SHOW_PERSONALIZED_PROGRAM_COPY = 'Show personalized program copy'
export const SUBMIT_SIGNUP_CREDENTIALS_FORM = 'Submitted signup credentials form'
export const OPEN_SIGN_UP_LANDER = 'Open signup lander'
export const OPEN_SIGN_IN_LANDER = 'Open login lander'
export const SIGN_UP_LANDER_SUBMIT = 'Signup lander: click Sign up'
export const OPEN_LIVE_LESSON_FEEDBACK = 'Open live lesson feedback'
export const OPEN_LIVE_LESSON_FEEDBACK_MISSING_LESSON = 'Open live lesson feedback - missing lesson'
export const SIGN_IN = 'Login'
export const SIGN_IN_FAILED = 'Login failed'
export const PROFILE_CREATION_COMPLETE = 'Profile creation complete'
export const PROFILE_CREATION_FAILED = 'Profile creation failed'
export const SIGN_UP_FAILED = 'Signup failed'
export const ONBOARDING_FAILED = 'Onboarding failed'
export const ONBOARDING_NOT_COMPLETE = 'Onboarding not complete'
export const ONBOARDING_START = 'Onboarding start'
export const CHANGE_SUBSCRIPTION_FLOW = 'Change subscription flow page'
export const SUBSCRIPTION_FLOW_FORWARD = 'Subscription flow forward'
export const SUBSCRIPTION_FLOW_BACKWARD = 'Subscription flow backward'
export const SUBSCRIPTION_FLOW_SELECT_PLAN = 'Subscription flow select plan'
export const SUBSCRIPTION_COUPON_ATTEMPTED = 'Subscription coupon attempted'
export const SELECT_SUBSCRIPTION_PLAN_INTERVAL = 'Select subscription plan interval'
export const STRIPE_CARD_FORM_SUBMIT_FAILURE = 'Stripe error: failed to submit card form'
export const CANCEL_SUBSCRIPTION_CLICK = 'Cancel subscription click'
export const SIGN_OUT = 'Logout'
export const OPEN_ASSESSMENT = 'Open assessment'
export const EXIT_ASSESSMENT = 'Exit assessment'
export const SAVE_ASSESSMENT = 'Save assessment'
export const RESTART_ASSESSMENT = 'Restart assessment'
export const COMPLETE_ASSESSMENT = 'Complete assessment'
export const START_ASSESSMENT = 'Start assessment'
export const DOWNLOAD_APP_CLICK = 'Download app click'

// onboarding payment related
export const SELECTED_PLAN_ONBOARDING = 'Selected plan onboarding'
export const ADD_PAYMENT_INFO_ONBOARDING = 'Add payment info onboarding'
export const PURCHASE_ONBOARDING = 'Purchase onboarding'
export const CARD_ADD_FAILED_ONBOARDING = 'Card add failed onboarding'
export const PAYMENT_FAILED_ONBOARDING = 'Payment failed onboarding'
export const STRIPE_CUSTOMER_CREATION_FAILURE_ONBOARDING =
  'Stripe customer creation failure onboarding'
export const PAYMENT_ONBOARDING_FAIL_FIRST_TRY = 'Payment onboarding fail first try'
export const PAYMENT_ONBOARDING_FAIL_SECOND_TRY = 'Payment onboarding fail second try'

export const OPEN_LESSON = 'Open lesson'
export const RESUME_LESSON = 'Resume lesson'
// export const START_LESSON = 'Start lesson' // event moved to backend
export const PAUSE_LESSON = 'Pause lesson'
export const COMPLETE_LESSON = 'Complete lesson'
export const EXIT_LESSON = 'Exit lesson'
// export const LESSON_PLAY_INTERVAL = 'Lesson play interval' // event moved to backend

export const RESUME_VIDEO = 'Resume video'
export const START_VIDEO = 'Start video'
export const PAUSE_VIDEO = 'Pause video'
export const COMPLETE_VIDEO = 'Complete video'
export const EXIT_VIDEO = 'Exit video'
export const VIDEO_PLAY_INTERVAL = 'Video play interval'

export const DASHBOARD_ACTIVITY_VIEW_ALL_BUTTON_CLICK =
  'Dashboard: Activity: View All Button: Clicked'
export const DASHBOARD_ACTIVITY_PROGRAM_BUTTON_CLICK =
  'Dashboard: Activity: Start Class Button: Clicked'
export const DASHBOARD_CHALLENGE_JOIN_BUTTON_CLICK = 'Dashboard: Challenge: Join Button: Clicked'
export const DASHBOARD_EXPLORE_LESSONS_VIEW_ALL_CLICK = 'Dashboard: ViewAllExploreClasses: Clicked'
export const DASHBOARD_LESSONS_FAVORITE_VIEW_ALL_CLICK =
  'Dashboard: ViewAllFavoriteClasses: Clicked'
export const DASHBOARD_LIVE_LESSONS_VIEW_ALL_CLICK = 'Dashboard: ViewAllLiveClasses: Clicked'
export const DASHBOARD_LIVE_LESSONS_SIGN_UP_CLICK = 'Dashboard: LiveClassSignUp: Clicked'
export const DASHBOARD_PROGRAM_BUTTON_CLICK = 'Dashboard: Program: View Program Button: Clicked'
export const DASHBOARD_PROGRAM_LESSON_BUTTON_CLICK = 'Dashboard: Program: Lesson Button: Clicked'
export const DASHBOARD_PROGRAM_SETUP_PROGRAM_BUTTON_CLICK =
  'Dashboard: Program: Setup Program Button: Clicked'

export const LOADED_A_PAGE = 'Loaded a Page'
export const START_FEEDBACK = 'Start feedback'
export const COMPLETE_FEEDBACK = 'Complete feedback'
export const WEEK_START = 'Week start'
export const WEEK_COMPLETE = 'Week complete'
export const EXPLORE_SEARCH = 'Explore search'
export const VIEWED_USER_STATS = 'Viewed user stats'
export const USER_DATA_UPDATED = 'User data updated'
export const LESSON_SCHEDULE_START = 'lesson_schedule_start'
export const LESSON_SCHEDULED = 'Lesson scheduled'
export const LESSON_FAVORITED = 'Lesson favorited'
export const LESSON_FAVORITE_REMOVED = 'Lesson favorite removed'
export const VIEW_LESSON_FAVORITES = 'View lesson favorites'
export const LIVE_CLASS_SCHEDULED = 'Live class scheduled'
export const LIVE_CLASS_CANCELLED = 'Live class cancelled'
export const JOIN_LIVE_CLASS_CLICKED = 'Join live class clicked'
export const SUBMIT_ONBOARDING_QUESTION = 'Submit onboarding question'
export const SWITCH_USER_EXERCISE_PROGRAM = 'switch_user_exercise_program'
export const JOIN_CHALLENGE = 'Click join challenge'
export const DAILY_CHECKIN_SUBMIT = 'Daily checkin submit'
export const CHECKIN_PROGRESS = 'Checkin progress'
export const FAILED_CHECKIN_CALL = 'Failed to get user checkin'
export const ENTERPRISE_CONVERSION_IDK_SUBMIT = 'Enterprise conversion idk submit'
export const ENTERPRISE_REEFORCA_CODE_FAILED = 'Confirmation code failed for reef/orca'
export const ENTERPRISE_REEFORCA_FORM_VALUE_CHANGED = 'Reef/orca enterprise form: Value changed'
export const ENTERPRISE_REEFORCA_FORM_SUBMIT = 'Reef/orca enterprise form: Submit'
export const ENTERPRISE_CONFIRM_TOS = 'Enterprise confirm terms of service from dashboard'
export const ENTERPRISE_MEMBER_LINKED = 'User linked with enterprise member'
export const ENTERPRISE_MEMBER_NOT_MATCHED_AT_ALL = 'User did not match any enterprise member'
export const ENTERPRISE_MEMBER_NOT_MATCHED_ON_NAME_DOB =
  'User did not match an enterprise member with name and dob'
export const MAILING_LIST_SIGN_UP = 'Mailing list sign up'
export const MAILING_LIST_SIGN_UP_TAG = 'Mailing list sign up'
export const ATTEMPTED_SIGN_IN = 'Attempted sign in'
export const OCTOPUS_VERIFICATION_MODAL = 'Octopus verification modal'
export const RESOURCE_REQUESTED = 'Resource requested'
export const REFERRALS_COPY_URL_BUTTON_CLICK = 'Referrals: Button: Copy'
export const REFERRALS_SEND_BUTTON_CLICK = 'Referrals: Button: Send'
export const REFERRALS_MODAL_OPEN = 'Modal: Referrals: Open Modal'
export const REFERRALS_FACEBOOK_BUTTON_CLICK = 'Referrals: Facebook Button Click'
export const POST_LESSON_MODAL_BADGE_SHARE_TO_FACEBOOK_BUTTON_CLICK =
  'Post Lesson Modal: Badge: Share To Facebook Button: Click'
export const SETTINGS_ENTERPRISE_ELIGIBILITY_BUTTON_CLICK =
  'Settings: Enterprise Eligibility Button: Click'
export const SUBSCRIPTION_CANCEL_BUTTON_CLICK = CANCEL_SUBSCRIPTION_CLICK
export const SUBSCRIPTION_PAYMENT_METHOD_CHANGE_BUTTON_CLICK =
  'Change Subscription Payment Method Button: Click'
export const SUBSCRIPTION_REACTIVATE_BUTTON_CLICK = 'Reactivate Subscription Button: Click'
export const SUBSCRIPTION_TRIAL_EXPIRATION_BANNER_CLICK =
  'Subscription Trial Expiration Banner: Click'
export const ALTERNATE_BALANCE_ASSESSMENT_VIEW = 'Alternate balance assessment view'
export const ALTERNATE_BALANCE_ASSESSMENT_COMPLETE = 'Alternate balance assessment complete'
export const PHONE_NUMBER_ADDED = 'Phone number added'
export const FACEBOOK_ENTERPRISE_ELIGIBILITY_RECHECK =
  'Special: Nay page: Eligibility Recheck Button click'
export const FACEBOOK_INELIGIBLE_USERS_TO_PROGRAM =
  'Special: Nay page: Continue To Program Button click'

// events where session user is not the identity we want
export const REFERRED_USER = 'Referred user'
export const ADMIN_RESET_WEEKLY_TAGS = 'Admin reset weekly tags'

export const SELECT_BASIC_SUBSCRIPTION = 'Subscription_Basic'
export const SUBSCRIPTION_PREMIUM_BI_ANNUAL = 'Subscription_Premium_Bi_Annual'
export const SUBSCRIPTION_PREMIUM_MONTHLY = 'Subscription_Premium_Monthly'
export const SUBSCRIPTION_PREMIUM_ANNUAL = 'Subscription_Premium_Annual'
export const USER_CANCEL_SUBSCRIPTION = 'Account_Cancelled'
export const REACTIVATE_SUBSCRIPTION = 'Account_Reactivated'

export const COMPLETED_BALANCE_ASSESSMENT = 'Completed_Balance_Assessment' // used only as Drip tag value
export const COMPLETED_MOBILITY_ASSESSMENT = 'Completed_Mobility_Assessment' // used only as Drip tag value
export const COMPLETED_STRENGTH_ASSESSMENT = 'Completed_Strength_Assessment' // used only as Drip tag value
export const SELECT_SEATED_DIFFICULTY = 'Level_Seated' // used only as Drip tag value
export const SELECT_STANDING_DIFFICULTY = 'Level_Standing' // used only as Drip tag value
export const USER_STARTED_ONBOARDING = 'User_Started_Onboarding' // used only as Drip tag value
export const USER_FINISHED_ONBOARDING = 'User_Finished_Onboarding' // used only as Drip tag value

export const INVITE_PEOPLE = 'Invite people' // not used
export const CHANGE_PROGRAM = 'Change program' // not used
export const HOME = 'home' // not used
export const BETA = 'beta' // not used
export const PUBLIC = 'public' // not used
export const HEADER = 'header' // not used
export const START_STRENGTH = 'start strength' // not used
export const START_MOBILITY = 'start mobility' // not used
export const START_BALANCE = 'start balance' // not used
export const ONBOARDING_QUESTION = 'Onboarding question' // not used
export const SITTING_STANDING_ONBOARDING_QUESTION_EVENT = `${ONBOARDING_QUESTION}_4` // not used

export const GET_STARTED = 'get started - email address' // used only as a string value
export const GET_STARTED_FB = 'get started - FB SSO' // used only as a string value
export const GET_STARTED_GOOGLE = 'get started - Google SSO' // used only as a string value
export const ALREADY_ON_BOLD = 'join pilot - already on Bold' // used only as a string value

export const HOME_HERO_TITLE_KEY = 'Home hero title'
export const SIGN_UP_FROM_KEY = 'Sign up from'

export const SIGN_UP_TYPE = {
  CONFIRMDETAILS: 'confirmDetails',
  EMAIL: 'email',
}

// Facebook standard events sent via Meta Pixel - OB Complete and Signup are currently also sent from BE via FB Conversions API
export const ADD_PAYMENT_INFO = 'Add payment info'
export const ONBOARDING_COMPLETE = 'Onboarding complete'
export const PURCHASE = 'Purchase'
export const SIGN_UP = 'Signup'
export const FACEBOOK_STANDARD_EVENTS = [ADD_PAYMENT_INFO, ONBOARDING_COMPLETE, PURCHASE, SIGN_UP]

// Facebook custom events
export const FACEBOOK_CUSTOM_EVENTS = [
  // we were told to stop sending HQL to facebook
  //ENTERPRISE_CONFIRM_TOS
]

export function getWeekStartedEvent(week: string) {
  return `Week_${week}_Started_Videos`
}

export function getWeekCompletedEvent(week: string) {
  return `Week_${week}_Completed_Videos`
}

export const SKIP_LESSON_CONFIRM = 'Skip Lesson Confirm'
export const SKIP_LESSON_MODAL_LOCKED = 'Skip Lesson Modal Locked'
export const SKIP_LESSON_BUTTON = 'Skip Lesson Button Click'
export const SKIP_LESSON_CANCEL = 'Skip Lesson Cancel'
export const SKIP_LESSON_CANCEL_FEEDBACK = 'Skip Lesson Cancel Feedback'
export const SKIP_LESSON_COMPLETE_FEEDBACK = 'Skip Lesson Complete Feedback'
