import { parsePhoneNumber } from 'libphonenumber-js'

function parse(value: string) {
  if (!value) return null

  try {
    const phone = parsePhoneNumber(value, 'US')

    return phone
  } catch (error) {
    console.error(error)

    return value
  }
}

// (###) ###-####
function format(value: string, formatType = 'NATIONAL') {
  const phone = parse(value)

  if (!phone) return value

  return phone.format(formatType)
}

// ##########
// 6074234747
export function digits(value: string) {
  const phone = parse(value)

  if (!phone) return value

  return phone.nationalNumber
}

// (###) ###-####
export const display = (value: string) => format(value)

// +1##########
export const e164 = (value: string) => format(value, 'E.164')

// tel:+1##########
export const uri = (value: string) => format(value, 'RFC3966')
